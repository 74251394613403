@use "sass:math";
@import "css/variables";

$_image-size: 3.5rem;
$_image-size--md: 4.5rem;

.MyExperience {
  &__Wrapper {
    position: relative;
    display: grid;
    border-bottom: 0.25rem solid $color-link;
    margin-top: 1.25rem;
    column-gap: 0.5rem;
    grid-template: #{math.div($_image-size, 2)} #{math.div($_image-size, 2)} auto / $_image-size auto;

    @media (min-width: $bp-md) {
      grid-template: #{math.div($_image-size--md, 2)} #{math.div($_image-size--md, 2)} auto / $_image-size--md auto;
    }
  }

  &__Wrapper:nth-child(even) {
    grid-template: #{math.div($_image-size, 2)} #{math.div($_image-size, 2)} auto / auto $_image-size;

    @media (min-width: $bp-md) {
      grid-template: #{math.div($_image-size--md, 2)} #{math.div($_image-size--md, 2)} auto / auto $_image-size--md;
    }
  }

  &__Wrapper:last-child {
    border: none;
  }

  &__SvgDate {
    width: $_image-size;
    height: $_image-size;

    @media (min-width: $bp-md) {
      width: $_image-size--md;
      height: $_image-size--md;
    }
  }

  &__Image {
    width: $_image-size;
    height: $_image-size;
    border: 0.25rem solid $color-link;
    margin-bottom: 1rem;
    border-radius: 100%;
    box-shadow: -0.1875rem 0.1875rem 0 0 $color-link-highlight;
    grid-row: 1 / 4;

    @media (min-width: $bp-md) {
      width: $_image-size--md;
      height: $_image-size--md;
    }
  }

  &__Wrapper:nth-child(even) &__Image {
    box-shadow: 0.1875rem 0.1875rem 0 0 $color-link-highlight;
  }

  &__Wrapper:nth-child(even) &__Image {
    grid-column: 2;
  }

  &__Summary {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    grid-column: 2;
    grid-row: 1 / 3;
  }

  &__Wrapper:nth-child(even) &__Summary {
    justify-content: end;
    grid-column: 1;
  }

  &__Place {
    flex: 1 0 100%;
    color: $color-link;
    font-family: $font-1;
    font-size: $font-size-md;
    font-weight: bold;
    line-height: 1;

    @media (min-width: $bp-md) {
      font-size: $font-size-lg;
    }
  }

  &__Wrapper:nth-child(even) &__Place {
    text-align: right;
  }

  &__Title {
    display: block;
    align-self: start;
    font-family: $font-1;
    font-size: $font-size-sm;
    font-style: italic;
    grid-column: 2;
  }

  &__Notes {
    font-size: $font-size-sm;
    grid-column: 1 / 3;
  }

  &__Note {
    margin-left: 1rem;
    list-style-type: disc;
  }

  &__Note::marker {
    color: $color-link;
  }
}
