@import "css/variables";
@import "css/styles";

.App {
  position: relative;
  padding-top: $header-height;
  background: $color-link;
  color: $color-text;
  font-family: $font-1;
  text-align: center;

  &__Content {
    display: grid;
    padding: 1rem 1rem 0;
    row-gap: 4rem;

    @media (min-width: $bp-md) {
      padding: 2rem 2rem 0;
    }

    @media (min-width: $bp-lg) {
      padding: 4rem 4rem 0;
    }
  }

  &__Section {
    position: relative;
    max-width: 60rem;
    padding: 1.5rem 0 0;
    margin: 0 auto;

    @media (min-width: $bp-lg) {
      overflow-x: hidden;
    }
  }

  &__End {
    width: 100%;
    padding-bottom: 2rem;
    background: $color-link;
  }
}
