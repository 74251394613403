.NoScroll { // stylelint-disable-line selector-class-pattern
  overflow: hidden;

  .App__Content::after {
    position: absolute;
    z-index: $z-body;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($color-black, 0.5);
    content: "";
  }
}
