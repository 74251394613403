@font-face {
  font-family: RobotoCondensed;
  src:
    local("RobotoCondensed"),
    url("../fonts/RobotoCondensed/RobotoCondensed.woff2") format("woff2");
}

@font-face {
  font-family: RobotoSlab;
  src:
    local("RobotoSlab"),
    url("../fonts/RobotoSlab/RobotoSlab.woff2") format("woff2");
}
