@import "css/variables";

$_right-margin: 1rem;

.BackToTop {
  position: fixed;
  z-index: $z-body;
  right: 0;
  bottom: 0;
  display: block;
  width: 3rem;
  height: 3rem;
  margin: 0 $_right-margin 1rem 0;
  background: $color-link-highlight;
  border-radius: 0.5rem;
  box-shadow: 0.1875rem 0.1875rem 0 0 rgba($color-black, 0.3);
  transform: none;
  transition: transform $anim-speed-default ease-in-out;

  &:hover {
    background: $color-link;
  }

  &--Hidden {
    transform: translateX(calc(100% + #{$_right-margin}));
  }

  &__Icon {
    width: 50%;
    height: 50%;
    fill: $color-white;
  }
}
