@import "css/variables";

.ProjectList {
  &__Image {
    width: 100%;
    border: 0.5rem solid $color-link;
    margin-bottom: 0.5rem;
    box-shadow: -0.375rem 0.375rem 0 0 $color-link-highlight;
  }

  &__Wrapper:nth-child(even) &__Image {
    box-shadow: 0.375rem 0.375rem 0 0 $color-link-highlight;
  }

  &__Summary {
    margin-bottom: 1rem;
    font-family: $font-1;
    font-weight: bold;
    line-height: 1.3;

    .TextLink {
      color: $color-link;
      font-size: $font-size-md;

      @media (min-width: $bp-md) {
        font-size: $font-size-lg;
      }

      &:hover {
        color: $color-link-highlight;
      }
    }
  }

  &__Notes {
    margin-bottom: 1.5rem;
    font-size: 1rem;
  }

  &__Note {
    margin-left: 1rem;
    list-style: disc;
  }

  &__Note::marker {
    color: $color-link;
  }
}
