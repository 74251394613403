@import "css/variables";

.NavMenu {
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  padding: 2rem 1.5rem;

  @media (min-width: $bp-lg) {
    display: grid;
    width: auto;
    align-items: start;
    margin: 0 auto;
    column-gap: 2rem;
    grid: repeat(2, auto) / repeat(2, minmax(auto, 10rem));
    grid-auto-flow: column;
  }

  &__Header {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: $color-text;
    font-size: $font-size-lg;
    font-weight: bold;
    text-transform: uppercase;

    @media (min-width: $bp-lg) {
      margin-top: 0;
    }
  }

  &__Header:first-child {
    margin-top: 0;
  }

  &__Link {
    display: block;
    width: max-content;
    margin: 0 auto 1rem;
    color: $color-text;
    font-size: $font-size-md;
    line-height: 1;
    text-decoration-color: $color-link;
    text-decoration-thickness: 0.1875rem;
    text-underline-position: under;
  }

  &__Link:hover {
    color: $color-link-highlight;
    text-decoration: none;
  }
}
