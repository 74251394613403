@import "css/variables";

.SocialLinksEnd {
  display: flex;
  justify-content: center;
  margin: auto auto 0;
  column-gap: 2rem;

  .IconLink {
    margin: 1rem 0;
    fill: $color-white;

    &:hover {
      fill: $color-link-highlight;
    }
  }
}
