@import "css/variables";

.TextLink {
  color: $color-text;
  text-decoration-color: $color-link;
  text-decoration-skip-ink: none;
  text-underline-position: under;

  &:hover {
    color: $color-link-highlight;
    text-decoration: none;
  }
}
