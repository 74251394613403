*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
div,
button,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
  padding: 0;
  border: 0;
  margin: 0;
  font: inherit;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  text-size-adjust: none;
  vertical-align: baseline;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  position: relative;
  line-height: 1;
}

li {
  margin-bottom: 0.25rem;
  list-style: none;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  appearance: none;
  border-radius: 0;
}

button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

p,
ul,
ol {
  margin-bottom: 1rem;
}
