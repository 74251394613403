@import "css/variables";

.SocialLinksMenu {
  display: flex;
  margin: 0 auto;

  .IconLink {
    margin: 0 1rem;

    @media (min-width: $bp-lg) {
      margin: 0 0.5rem;
    }
  }
}
