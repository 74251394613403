@import "css/variables";

$_border-height: 0.25rem;
$_nav-padding-vertical: 0.875rem;
$_nav-padding-horizontal: 1.5rem;

.GlobalNav {
  position: fixed;
  z-index: $z-nav;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: $header-height;
  align-items: flex-start;
  padding: $_nav-padding-vertical $_nav-padding-horizontal;
  border-bottom: $_border-height solid $color-link;
  backdrop-filter: blur(1.25rem);
  background: rgba($color-white, 0.95);
  transition: all $anim-speed-default ease-in-out;
  transition-property: height, width, background;

  &--MenuOpen {
    height: 25.5rem;
    background: $color-white;

    @media (min-width: $bp-lg) {
      width: 30rem;
      height: 19rem;
      border-right: $_border-height solid $color-link;
    }
  }

  &--MenuOpen::after {
    opacity: 1;
  }

  &--Minimized:not(&--MenuOpen) {
    height: $header-height--min;
  }

  &__MenuButton {
    position: absolute;
    z-index: $z-body;
    top: $_nav-padding-vertical;
    left: $_nav-padding-horizontal;
    display: flex;
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-content: space-around;
    fill: $color-link;
  }

  &__MenuButtonShape {
    width: 100%;
    height: 0.25rem;
    background: $color-link;
  }

  &__MenuButton:hover &__MenuButtonShape {
    background: $color-link-highlight;
  }

  &__MenuButtonTop {
    transform: rotate(0);
    transform-origin: top left;
    transition: transform $anim-speed-default ease-in-out;
  }

  &--MenuOpen &__MenuButtonTop {
    transform: rotate(45deg);
  }

  &__MenuButtonMiddle {
    transform: translateX(0);
    transition: transform $anim-speed-default ease-in-out;
  }

  &--MenuOpen &__MenuButtonMiddle {
    transform: translateX(calc(-100% - #{$_nav-padding-horizontal}));
  }

  &__MenuButtonBottom {
    transform: rotate(0);
    transform-origin: bottom left;
    transition: transform $anim-speed-default ease-in-out;
  }

  &--MenuOpen &__MenuButtonBottom {
    transform: rotate(-45deg);
  }

  &__Header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: auto;
    text-align: right;

    @media (min-width: $bp-md) {
      width: 100%;
      flex-flow: row wrap;
    }
  }

  &__HeaderTitle {
    display: block;
    margin-bottom: 0.5rem;
    color: $color-text;
    font-size: $font-size-lg;
    font-weight: bold;
    grid-column: 1 / -1;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__HeaderTitle:hover {
    color: $color-link;
  }

  &__RowSeparator {
    display: none;
    height: 0;
    flex: 1 0 100%;

    @media (min-width: $bp-md) {
      display: block;
    }
  }

  &__HeaderSubTitle {
    display: block;
    font-size: $font-size-sm;
    transition: opacity $anim-speed-default ease-in-out;
  }

  &--Minimized:not(&--MenuOpen) &__HeaderSubTitle,
  &--Minimized:not(&--MenuOpen) .SocialLinks, {
    opacity: 0;
  }

  &__HeaderSubTitleLine {
    display: block;
  }
}
