@import "css/breakpoints";
@import "css/colors";
@import "css/fonts";

/* Ignore variable pattern for global variables */
/* stylelint-disable scss/dollar-variable-pattern */

/*
FONT
*/

$font-1: "RobotoCondensed", arial, sans-serif;
$font-2: "RobotoSlab", georgia, serif;
$font-size-xs: 0.875rem;
$font-size-sm: 1rem;
$font-size-md: 1.5rem;
$font-size-lg: 2rem;
$font-size-xl: 3rem;
$font-size-xxl: 4rem;
$font-size-mega: 6rem;

/*
ANIMATION
*/

$anim-speed-default: 0.25s;
$anim-speed-crawl: 1s;

/*
Z-INDEX
*/
$z-behind: -1;
$z-body: 1;
$z-nav: 2;

/*
LAYOUT
*/

$header-height: 6.5rem;
$header-height--min: 3.875rem;
$border-gradient: linear-gradient(to left, $color-link-highlight, $color-link);
$section-offset: 6rem;
