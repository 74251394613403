@import "css/variables";

.MySkills {
  &__SkillHeader {
    margin-bottom: 0.5rem;
    color: $color-link;
    font-family: $font-1;
    font-size: $font-size-lg;
    font-weight: bold;
  }

  &__SkillList {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    gap: 0.5rem;
  }

  &__Skill {
    display: block;
    width: fit-content;
    padding: 0.5rem;
    background: $color-link;
    color: $color-white;
  }
}
