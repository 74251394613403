@import "css/variables";

.IconLink {
  width: 2rem;
  height: 2rem;
  fill: $color-link;

  &:hover {
    fill: $color-link-highlight;
  }
}
